import * as React from "react";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import Typography from "src/components/Typography";

interface Props {
  text: string;
  header: string;
}

export const TextSegment: React.SFC<Props> = (props) => {
  if (!props.text) return null;
  return (
    <>
      <br />
      <SectionHeader header={props.header} />
      <Typography type="text">
        <div
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        />
      </Typography>
      <br />
    </>
  );
};

export default TextSegment;
