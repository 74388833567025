import React from "react";
import Teachers from "src/components/Persons";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import Typography from "src/components/Typography";
import TextSegment from "./TextSegment";

import { Course } from "src/types";
import styles from "../AboutPage.module.css";

interface Props {
  aboutText: string;
  forWho: string;
  teachers: Course["teachers"];
  whatYouGet: string;
}

export const AboutSection: React.SFC<Props> = (props) => {
  return (
    <section className={styles.aboutWrapper}>
      <div className={styles.BG} />
      <div className={styles.outerWrapper}>
        <div className={styles.content}>
          <TextSegment header="О курсе" text={props.aboutText} />
          <TextSegment header="Для кого курс" text={props.forWho} />
          <Teachers teachers={props.teachers} header="Преподаватели" />
          <TextSegment
            header="Что вы получите в итоге"
            text={props.whatYouGet}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
