import * as React from "react";
import Button from "src/components/Button/Button";
import Typography from "src/components/Typography";
import eyeIcon from "src/images/little-eye.svg";
import redConfirm from "src/images/red-confirm.svg";
import { Good } from "src/types";
import styles from "./BlankBlock.module.css";

type P = {
  name: string;
  good: Good;
  features: string[];
  startDate?: string | null;
  handlePay(good: Good): void;
};

const BlankBlock = (props: P) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={eyeIcon} />
        <Typography type="h3">{props.name}</Typography>
        <Typography type="h3">{props.good.price / 100 + " Р"}</Typography>
      </div>
      <div>{props.features.map(priceFeature)}</div>
      {props.startDate && (
        <div className={styles.startDate}>
          <Typography type="h4">Старт {props.startDate}</Typography>
        </div>
      )}
      <Button handleClick={() => props.handlePay(props.good)}>
        <Typography type="h4">Купить</Typography>
      </Button>
    </div>
  );
};

const priceFeature = (text: string) => (
  <div className={styles.priceFeature}>
    <div>
      <img src={redConfirm} />
    </div>
    <Typography type="h5">{text}</Typography>
  </div>
);

export default BlankBlock;
