import * as React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import Button from "src/components/Button";
import TextInput from "src/components/TextInput";
import Typography from "src/components/Typography";
import styles from "./Form.module.css";

interface Props extends InjectedFormProps<FormData> {}

type FormData = {
  email: string;
  handleSubmit(): Promise<void>;
};

class SendFrom extends React.Component<Props> {
  public render() {
    const { props } = this;
    const isDisabled = !!(
      props.submitting ||
      !props.anyTouched ||
      props.error ||
      props.invalid
    );

    return (
      <form onSubmit={props.handleSubmit} className={styles.formWrapper}>
        <div>
          <div className={styles.textWrapper}>
            <Typography type="h3" black>
              <h3>Оставь свою почту</h3>
            </Typography>
            <br />
            <Typography type="text" black>
              и мы вышлем тебе бесплатный урок!
            </Typography>
          </div>
          <br />
          <div className={styles.inputWrapper}>
            <TextInput name="email" label="email" black />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button disabled={isDisabled} type="submit">
            ОТПРАВИТЬ
          </Button>
        </div>
      </form>
    );
  }
}

const validate = (values: FormData) => {
  const errors: { email?: string; name?: string } = {};
  const requiredFields = ["email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Обязательное поле";
      return;
    }
    // tslint:disable-next-line: no-unsafe-any
    if (values[field].length < 2) {
      errors[field] = "Недостаточная длинна значения";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export default reduxForm<FormData>({
  form: "SendFrom", // a unique identifier for this form
  validate,
})(SendFrom);
