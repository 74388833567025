import * as React from "react";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import ModalWrapper from "src/components/ModalWrapper/ModalWrapper";
import Typography from "src/components/Typography";
import { Course } from "src/types";
import styles from "./FirstLesson.module.css";
import Form from "./Form";

interface Props {
  show: Course["isFirstLesson"];
  courseId: string;
}

interface S {
  isOpen: boolean;
}

class FirstLesson extends React.Component<Props, S> {
  public state = {
    isOpen: false,
  };

  public toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  public render() {
    if (!this.props.show) return null;
    return (
      <div className={styles.wrapper}>
        <Typography type="h3">Получить первый урок бесплатно</Typography>
        <Button handleClick={this.toggle}>
          <Typography type="h4">Получить</Typography>
        </Button>
        <div>
          <Modal
            isOpen={this.state.isOpen}
            onRequestClose={this.toggle}
            className={styles.modalWrapper}
            overlayClassName={styles.overlayClassName}
            white
          >
            <Form courseId={this.props.courseId} close={this.toggle} />
          </Modal>
        </div>
      </div>
    );
  }
}

export default FirstLesson;
