import * as React from "react";
import Typography from "src/components/Typography";
import classes from "./Features.module.css";

interface Props {
  header: string;
  text: string;
  icon: string;
  render: boolean;
}

const Feature: React.SFC<Props> = (props) => {
  if (!props.render) return null;

  return (
    <div className={classes.featureItem}>
      <div className={classes.featureIcon}>
        <img src={props.icon} alt={props.header} />
      </div>
      <div className={classes.featureText}>
        <Typography type="h5">{props.header}</Typography>
        <Typography type="text">{props.text}</Typography>
      </div>
    </div>
  );
};

export default Feature;
