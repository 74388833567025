import { Link } from "gatsby";
import React from "react";
import ArrowButton from "src/components/ArrowButton";
import IconOnImage from "src/components/IconOnImage";
import Typography from "src/components/Typography";
import styles from "./Announce.module.css";

export type Props = {
  announce: {
    img: string;
    name: string;
    announceDescription?: string;
  };
  link: string;
  iconOnImage?: string;
};

const Announce: React.SFC<Props> = (props) => {
  return (
    <div className={styles.wrapper}>
      <Link to={props.link} className={styles.imgWrapper}>
        <img src={props.announce.img} alt="обложка" />
        <IconOnImage icon={props.iconOnImage} />
      </Link>
      <div className={styles.name}>
        <Typography type="h4">
          <h4>{props.announce.name}</h4>
        </Typography>
      </div>
      {props.announce?.announceDescription ? (
        <div className={styles.txtWrapper}>
          <Typography type="text">
            {props.announce.announceDescription}
          </Typography>
        </div>
      ) : null}
      {props.children}
      <div>
        <Link to={props.link}>
          <ArrowButton />
        </Link>
      </div>
    </div>
  );
};

export default Announce;
