import * as React from "react";
import Expand from "react-expand-animated";
import Typography from "src/components/Typography";
import closeIcon from "src/images/close-qa.svg";
import openIcon from "src/images/open-qa.svg";
import scenarioModuleCss from "./SingleQA.module.css";

interface Props {
  question: string;
  answer: string;
}
interface State {
  isOpen: boolean;
}

class SingleQAndA extends React.Component<Props, State> {
  public state = {
    isOpen: false,
  };

  public toggleExpand = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public render() {
    const { props } = this;
    const icon = this.state.isOpen ? closeIcon : openIcon;
    return (
      <div
        onClick={this.toggleExpand}
        className={scenarioModuleCss.expandRowWrapper}
        role="button"
      >
        <div className={scenarioModuleCss.questionRow}>
          <Typography type="biggerText">{props.question}</Typography>
          <img src={icon} alt="открыть ответ" />
        </div>
        {this.renderAnswer()}
      </div>
    );
  }

  private renderAnswer() {
    return (
      <Expand open={this.state.isOpen}>
        <div className={scenarioModuleCss.answer}>
          <Typography type="text">{this.props.answer}</Typography>
        </div>
      </Expand>
    );
  }
}

export default SingleQAndA;
