import React, { ElementType } from "react";
import Typography from "src/components/Typography";
import styles from "./SectionHeader.module.css";

type Props = {
  header: string;
  children?: React.ReactNode;
};

const SectionHeader: React.SFC<Props> = data => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerContent}>
        <Typography type="h4">
          <h4>{data.header}</h4>
        </Typography>
        <div>{data.children || ""}</div>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export default SectionHeader;
