import React from "react";
import Expand from "react-expand-animated";
import ArrowButton from "src/components/ArrowButton";
import SectionHeader from "src/components/SectionHeader";
import Typography from "src/components/Typography";
import { Person } from "src/types";
import styles from "./Teachers.module.css";

interface State {
  isOpen: boolean;
}

type Props = { teachers: Person[]; header?: string };

class Teachers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public render() {
    const { isOpen } = this.state;
    if (!this.props.teachers?.length) {
      return null;
    }

    return (
      <section className={styles.sectionWrapper}>
        <SectionHeader header={this.props.header || "Спикеры"}>
          {this.props.teachers.length > 6 ? (
            <div onClick={this.toggleOpen} role="button">
              <ArrowButton text={!isOpen ? "Смотреть всех" : "Свернуть"} />
            </div>
          ) : null}
        </SectionHeader>
        <div className={styles.teachersWrapper}>
          {this.props.teachers.slice(0, 6).map(this.renderItem)}
        </div>
        <Expand open={isOpen}>
          <div className={`${styles.teachersWrapper} ${styles.additionalRow}`}>
            {this.props.teachers.slice(6).map(this.renderItem)}
          </div>
        </Expand>
      </section>
    );
  }

  private renderItem = (item: Person, i: number) => {
    return (
      <div key={item._id} className={styles.itemWrapper}>
        <div className={styles.imageWrapper}>
          <img src={item.img} alt={`"фото ${item.name}`} />
        </div>
        <div className={styles.textWrapper}>
          <Typography type="boldText">{item.name}</Typography>
          <Typography type="text">{item.description}</Typography>
        </div>
      </div>
    );
  };

  private toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}

export default Teachers;
