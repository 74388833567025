import * as React from "react";
import ReactModal from "react-modal";
import times from "src/images/blackTimes.svg";
import whiteTimes from "src/images/whiteTimes.svg";
// tslint:disable-next-line: import-name
import styles from "./Modal.module.css";
import classes from "./Modal.module.css";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  isBroadcast?: boolean;
  overlayClassName?: string;
  isScenario?: boolean;
  white?: boolean;
  onRequestClose(): void;
}

class Modal extends React.Component<Props> {
  public render() {
    const { props } = this;
    return (
      <ReactModal
        isOpen={props.isOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={props.onRequestClose}
        className={`${styles.modalContent} ${this.props.className || ""}`}
        overlayClassName={`${styles.modalOverlay} ${
          this.props.overlayClassName || ""
        }`}
      >
        {this.props.children}
        {this.renderCloseButton()}
      </ReactModal>
    );
  }

  private renderCloseButton() {
    return (
      <div className={classes.closeButtonWrapper}>
        <span
          role="button"
          className={classes.closeButton}
          onClick={this.props.onRequestClose}
        >
          <img src={this.props.white ? times : whiteTimes} alt="close button" />
        </span>
      </div>
    );
  }
}

export default Modal;
