import * as React from "react";
import Api from "src/api";
import Seo from "src/components/Seo";
import AboutRoute from "src/routes/School/AboutPage";
import { CourseAboutPage, CourseAnnounce, PageProps } from "src/types";

interface Props extends PageProps {
  data?: CourseAboutPage;
}
interface State {
  course?: CourseAboutPage;
  announces: CourseAnnounce[];
}

class AboutCourse extends React.Component<Props, State> {
  public state: State = {
    course: null,
    announces: [],
  };

  public async componentDidMount() {
    try {
      await this.getCourseAboutPage();
      await this.getSchoolAnnounces();
    } catch (error) {
      // tslint:disable-next-line: no-unsafe-any
      console.error(error && error.message);
    }
  }

  public async componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      await this.getCourseAboutPage();
      window.scroll({
        top: 0,
      });
    }
  }

  public render() {
    const crs = this.state.course;
    if (!crs) return null;
    return (
      <>
        <Seo
          pageTitle={crs.name}
          pageDescription={(crs.description || "").replace(/<(.|\n)*?>/g, "")}
        />
        <AboutRoute {...crs} {...this.props} announces={this.state.announces} />
      </>
    );
  }

  private getCourseAboutPage = async () => {
    const res = await Api.getCourseAboutPage(this.props["*"]);
    this.setState({ course: res.data });
  };

  private getSchoolAnnounces = async () => {
    const res = await Api.getSchoolAnnounces({}, { limit: 4 });
    this.setState({
      announces: res.data.data,
    });
  };
}

export default AboutCourse;
