import * as React from "react";
import Typography from "src/components/Typography";
import { Course, Good } from "src/types";
import BlankBlock from "./BlankBlock";
import { homeAlone, live } from "./features.json";
import styles from "./styles.module.css";

type P = {
  good: Good;
  lightGood?: Good;
  startDate?: string;
  featureBlocks?: Course["featureBlocks"];
  handlePay(good: Good): void;
};

const PriceBlock = (props: P) => {
  return (
    <div id="price-offer" className={styles.sectionWrapper}>
      <div className={styles.header}>
        <Typography type="h3">Стоимость курса</Typography>
      </div>
      <div className={styles.wrapper}>
        {props.lightGood && (
          <BlankBlock
            name="Home alone"
            good={props.lightGood}
            handlePay={props.handlePay}
            features={[ ...homeAlone, ...(props.featureBlocks?.cheap || [])]}
          />
        )}
          {props.good && (
              <BlankBlock
                  name="Live"
                  good={props.good}
                  handlePay={props.handlePay}
                  startDate={props.startDate}
                  features={[...live, ...(props.featureBlocks?.rich || [])]}
              />
          )}
      </div>
    </div>
  );
};

export default PriceBlock;
