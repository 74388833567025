import Vimeo from "@u-wave/react-vimeo";
import { navigate, PageProps } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import Announce from "src/components/Announce";
import Button from "src/components/Button/Button";
import PageHeader from "src/components/PageHeader/PageHeader";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import { CourseAboutPage, CourseAnnounce, Good, User } from "src/types";
import styles from "./AboutPage.module.css";
import AboutSection from "./AboutSection";
import FirstLesson from "./AboutSection/FirstLesson";
import CourseBaner from "./CourseBaner";
import FAQ from "./FAQ";
import Features from "./Features";
import PriceBlock from "./PriceBlock";
import Reviews from "./Reviews";

interface ConnectStateProps {
  user?: User;
}
interface Props extends ConnectStateProps, CourseAboutPage, PageProps {
  className?: string;
  announces: CourseAnnounce[];
}

class AboutPage extends React.Component<Props> {
  public render() {
    const { props } = this;
    return (
      <div>
        <PageHeader text={props.name}>
          <div>
            <Button handleClick={this.handleButtonClick}>
              {!props.payedByUser ? "Купить курс" : "Смотреть курс"}
            </Button>
          </div>
        </PageHeader>
        <br />
        <Features
          elkMaterials={props.elkMaterials}
          duration={props.duration}
          price={props.good?.price}
          homeworksCount={props.homeworksCount}
        />
        {props.preview?.teaser && (
          <div className={styles.videoWrapper}>
            {/* tslint:disable-next-line: no-unsafe-any */}
            <Vimeo
              responsive
              video={props.preview.teaser}
              className={styles.vimeoPlayer}
            />
          </div>
        )}
        {!props.preview?.teaser && props.preview?.img && (
          <div className={styles.imgWrapper}>
            <img src={props.preview.img} />
          </div>
        )}
        <br />
        <AboutSection
          teachers={props.teachers}
          aboutText={props.aboutText}
          forWho={props.description}
          whatYouGet={props.preview?.description}
        />
        <FirstLesson show={!!props.isFirstLesson} courseId={props._id} />
        {!props.payedByUser && (
          <PriceBlock
            good={props.good}
            lightGood={props.lightGood}
            startDate={props.startDate}
            handlePay={this.handlePayButtonClick}
            featureBlocks={props.featureBlocks}
          />
        )}
        <CourseBaner {...props.baner} handleClick={this.handleButtonClick} />
        <FAQ faq={props.faq} />
        <Reviews data={props.reviews} />
        {this.props.announces?.length && (
          <div className={styles.announcesWrapper}>
            <SectionHeader header="Рекомендованные курсы" />
            <div className={styles.mappedAnnounces}>
              {props.announces.map((curr, i) => {
                return (
                  <Announce
                    key={i}
                    announce={{
                      img: curr.announce.img,
                      announceDescription: curr.announce?.description,
                      name: curr.name,
                    }}
                    link={`/school/about/${curr.latinName}`}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  public handlePayButtonClick = async (good: Good) => {
    const { props } = this;

    if (!props.user?.email) {
      await navigate("/register", {
        state: {
          modal: true,
          noScroll: true,
          closeTo: props.location.pathname,
        },
      });
      return;
    }

    if (good && !props.payedByUser) {
      await navigate("/pay", { state: { good } });
      return;
    }

    await navigate(`/school/courses/${props.latinName}`);
  };

  public handleButtonClick = async () => {
    const { props } = this;

    if (!props.user?.email) {
      await navigate("/register", {
        state: {
          modal: true,
          noScroll: true,
          closeTo: props.location.pathname,
        },
      });
      return;
    }

    const el = document.getElementById("price-offer");
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
      return;
    }

    await navigate(`/school/courses/${props.latinName}`);
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
  // tslint:enable no-unsafe-any
});

export default connect<ConnectStateProps>(mapStateToProps)(AboutPage);
