import * as React from "react";
import styles from "./IconOnImage.module.css";

interface Props {
  icon?: string;
  big?: boolean;
}

export const IconOnImage: React.SFC<Props> = (props) => {
  if (!props.icon) return null;
  return (
    <div
      className={[styles.iconOnImageWrapper, props.big && styles.big].join(" ")}
    >
      <img src={props.icon} alt="icon" />
    </div>
  );
};

export default IconOnImage;
