import * as React from "react";
import Typography from "src/components/Typography";
import { Course } from "src/types";
import styles from "./FAQ.module.css";
import SingleQA from "./SingleQA";

type P = {
  faq?: Course["faq"];
};

const FAQ = (props: P) => {
  if (!props.faq?.length) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography type="h3">FAQ</Typography>
      </div>
      <div className={styles.body}>
        {props.faq.map((curr, i) => (
          <SingleQA key={i} question={curr.title} answer={curr.text} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
