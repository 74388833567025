import * as React from "react";
import bookIcon from "src/images/books.svg";
import calendar from "src/images/calendar.svg";
import elkmaterial from "src/images/elkmaterial.svg";
import numerals from "src/utils/numerals";

import { CourseAboutPage } from "src/types";
import Feature from "./Feature";
import classes from "./Features.module.css";

interface Props {
  elkMaterials: CourseAboutPage["elkMaterials"];
  duration: CourseAboutPage["duration"];
  price: CourseAboutPage["good"]["price"];
  homeworksCount: CourseAboutPage["homeworksCount"];
}

export const Features: React.SFC<Props> = (props) => {
  const duration = props.duration;
  if (!props.elkMaterials && !props.duration && !props.homeworksCount)
    return null;

  return (
    <div className={classes.featureContainer}>
      <Feature
        icon={elkmaterial}
        header="Электронные материалы"
        text=""
        render={!!props.elkMaterials}
      />
      <Feature
        icon={calendar}
        header="Продолжительность"
        text={`${duration} ${numerals(duration, ["урок", "урока", "уроков"])} `}
        render={!!props.duration}
      />
      <Feature
        icon={bookIcon}
        header="Домашние задания"
        text={`${props.homeworksCount}`}
        render={!!props.homeworksCount}
      />
    </div>
  );
};

export default Features;
