import * as React from "react";
import SectionHeader from "src/components/SectionHeader/SectionHeader";
import Typography from "src/components/Typography";
import { Course } from "src/types";
import classes from "./Reviews.module.css";

interface Props {
  data: Course["reviews"];
}

export const Reviews: React.SFC<Props> = (props) => {
  if (!props.data?.length) return null;
  return (
    <div className={classes.reviewsContainer}>
      <SectionHeader header="Отзывы" />
      <div className={classes.reviewsWrapper}>
        {props.data.map((curr) => (
          <div className={classes.review}>
            <Typography type="boldText">{curr.title}</Typography>
            <Typography type="text">
              {/* tslint:disable-next-line */}
              <span
                dangerouslySetInnerHTML={{
                  __html: curr.text,
                }}
              />
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Reviews;
