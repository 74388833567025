import * as React from "react";
import Api from "src/api";
import Form from "./Form";

interface P {
  courseId: string;
  close(): void;
}

class FormContainer extends React.Component<P> {
  public render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit} />
      </div>
    );
  }
  public handleSubmit = async ({ email }: { email: string }) => {
    await Api.sendFirstLessonForm({ email, courseId: this.props.courseId });
    window.alert("мы отправили вам письмо!");
    this.props.close();
  };
}

export default FormContainer;
