import * as React from "react";
import Button from "src/components/Button/Button";
import Typography from "src/components/Typography";
import banerIcon from "src/images/course-baner-icon.svg";
import { Course } from "src/types";
import classes from "./CourseBaner.module.css";

type CourseBaner = Course["baner"];

interface Props extends CourseBaner {
  handleClick(): void;
}

const CourseBaner: React.SFC<Props> = (props) => {
  if (!props.sale || !props.name) return null;
  return (
    <div>
      <div className={classes.banerWrapper}>
        <div className={classes.banerIcon}>
          <img src={banerIcon} />
        </div>
        <div className={classes.saleText}>
          <Typography type="h2">{props.sale}%</Typography>
          <Typography type="h2">скидка</Typography>
        </div>
        <div className={classes.descriptionWrapper}>
          <div className={classes.descriptionText}>
            <Typography type="h3">{props.name}</Typography>
            <Typography type="h4">{props.description}</Typography>
          </div>
          <div className={classes.whiteLines}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
      <div className={classes.startButton}>
        <Button
          style={{ background: "#6483F0" }}
          handleClick={props.handleClick}
        >
          Купить
        </Button>
      </div>
    </div>
  );
};

export default CourseBaner;
